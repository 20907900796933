import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '@/application/layouts/Layout';
import { LoginForm } from '@/login/pages/login/components/LoginForm';
import LineDivider from '@/shared/components/LineDivider';

type Props = { notice?: string, alert?: string };

function LoginIndex({ notice, alert }: Props) {
  const { t } = useTranslation();
  return (
    <Layout>
      <h1 className="mb-0">{t('sign_in.title')}</h1>
      <p className="text-muted">{t('sign_in.subtitle')}</p>

      <LineDivider />
      <LoginForm />
    </Layout>
  );
}

export default LoginIndex;
