import {
  faqPath,
  newUserPasswordPath,
  newUserSessionPath, rootPath, userAttemptSsoPath,
} from '@routes';
import React, { ReactNode, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import LineDivider from '@/shared/components/LineDivider';
import { useAxios } from '@/shared/hooks/useApi';
import { redirectTo } from '@/shared/utilities/redirect';

function SubmitButton({ disabled, children }: { disabled?: boolean, children: ReactNode }) {
  return (
    <Button
      type="submit"
      variant="primary"
      className="w-100"
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

function NeedHelpLink() {
  const { t } = useTranslation();
  return <a href={faqPath()} className="text-decoration-underline fw-medium">{t('menu.need_help')}</a>;
}

function PasswordResetLink({ email = '' }: { email?: string }) {
  const { t } = useTranslation();
  return (
    <a
      href={`${newUserPasswordPath()}?email=${email}`}
      className="text-decoration-underline fw-medium"
    >
      {t('link.forgot_password')}
    </a>
  );
}

export function LoginForm() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  // Refs are used for autofocus
  const passwordRef = useRef<HTMLInputElement>();
  const otpRef = useRef<HTMLInputElement>();

  const [password, setPassword] = useState('');
  const [passwordRequired, setPasswordRequired] = useState(false);

  const [otp, setOtp] = useState('');
  const [otpRequired, setOtpRequired] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);

  const [error, setError] = useState('');
  const [{ loading: loadingSessionUrl }, fetchSamlSessionUrl] = useAxios(
    { url: userAttemptSsoPath(), method: 'POST', data: { email } },
    { manual: true },
  );
  const [{ loading: loadingLoginAttempt }, login] = useAxios(
    {
      url: newUserSessionPath(),
      method: 'POST',
      data: {
        user: {
          email,
          password,
          ...(otp && { otp }),
          remember_me: rememberMe,
        },
      },
    },
    { manual: true },
  );

  const discoverLoginMethod = async (e) => {
    e.preventDefault();
    const { data } = await fetchSamlSessionUrl();
    if (data.action === 'AskForPassword') {
      setPasswordRequired(true);
      passwordRef.current.focus();
    } else {
      const { redirect_url: redirectUrl } = data;
      redirectTo(redirectUrl);
    }
  };

  const rememberMeHandler = (value) => setRememberMe((value === 'on'));

  const loginAttempt = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const { data } = await login();

      if (data.action === 'RedirectToDashboard') {
        redirectTo(rootPath());
      } else if (data.action === 'AskForOtp') {
        setOtpRequired(true);
        otpRef.current.focus();
      } else if (data.error) {
        setError(data.error);
      }
    } catch (err) {
      setError(t('devise.failure.invalid'));
    }
  };

  const submitButton = loadingSessionUrl || loadingLoginAttempt
    ? <SubmitButton disabled>{t('message.loading')}</SubmitButton>
    : <SubmitButton>{passwordRequired ? t('sign_in.title') : t('link.continue')}</SubmitButton>;

  return (
    <form aria-label="form" onSubmit={passwordRequired ? loginAttempt : discoverLoginMethod}>
      {!otpRequired && (
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>{t('activerecord.attributes.user.email')}</Form.Label>
          <Form.Control
            type="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
      )}

      {passwordRequired && !otpRequired && (
        <>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t('activerecord.attributes.user.password')}</Form.Label>
            <Form.Control
              ref={passwordRef}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              autoFocus
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="rememberMe">
            <Form.Check
              label={t('activerecord.attributes.user.remember_me')}
              onChange={(e) => rememberMeHandler(e.target.value)}
            />
          </Form.Group>
        </>
      )}

      {otpRequired && (
        <Form.Group className="mb-3" controlId="otp">
          <Form.Label>{t('otp.enter_otp_code')}</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setOtp(e.target.value)}
            ref={otpRef}
            required
          />
        </Form.Group>
      )}
      {error && <p className="form-error">{error}</p>}

      <div className="form-group my-3">{submitButton}</div>

      <LineDivider />
      {!passwordRequired && (
        <NeedHelpLink />
      )}

      {passwordRequired && (
        <PasswordResetLink email={email} />
      )}
    </form>
  );
}
